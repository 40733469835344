<template>
  <UiContainer tag="ul" class="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6 md:flex">
    <li
      v-for="(link, index) in links"
      :key="link.title"
      :class="{ 'col-span-2 sm:col-span-1': index === 0 }"
    >
      <UiButton
        :tag="link.external ? 'a' : 'RouterLink'"
        severity="gradient"
        :to="link.to"
        :href="link.to"
        class="relative flex w-full justify-between overflow-hidden rounded-lg border border-fivefold p-3 sm:max-w-[266px]"
      >
        <div class="relative">
          <p class="mb-1 font-medium text-text-main">
            {{ link.title }}
          </p>
          <p v-if="link.description" class="text-sm text-text-secondary">
            {{ link.description }}
          </p>
        </div>
        <UiIcon name="chevron-down" class="mt-1.5 size-4 min-w-4 -rotate-90 text-icon-quaternary" />
      </UiButton>
    </li>
  </UiContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { FAQLinks } from '@/5_entities/FAQ'
import { UiButton, UiContainer, UiIcon } from '@/6_shared/ui'

const links = computed(() => FAQLinks.filter((_, count) => count < FAQLinks.length - 1))
</script>
