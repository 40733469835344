<template>
  <main class="relative py-5">
    <UiGradientBackground class="absolute inset-0" />
    <UiContainer tag="section" class="mb-6">
      <UiButton
        size="small"
        severity="tertiary"
        class="flex w-full items-center justify-between rounded-lg bg-primary py-3 text-left"
        @click="showLocationDialog = true"
      >
        <div>
          <p class="text-sm text-text-secondary">город</p>
          <p class="text-base font-medium">{{ currentLocation?.displayName || 'Москва' }}</p>
        </div>
        <UiIcon name="chevron-down" class="size-4 -rotate-90 text-icon-quaternary" />
      </UiButton>
    </UiContainer>
    <UiContainer tag="section">
      <h1 class="mb-3 text-sm font-medium pl-3">Нужна помощь?</h1>
      <ul>
        <li v-for="link in FAQLinks" :key="link.title" class="mb-3 last:mb-0 last:mt-6">
          <Component
            :is="link.external ? 'a' : 'RouterLink'"
            :to="link.to"
            :href="link.to"
            class="flex w-full items-center justify-between rounded-lg bg-primary p-3"
          >
            <div class="relative">
              <p class="mb-1 font-medium">
                {{ link.title }}
              </p>
              <p v-if="link.description" class="text-sm text-text-secondary">
                {{ link.description }}
              </p>
            </div>
            <UiIcon name="chevron-down" class="size-4 min-w-4 -rotate-90 text-icon-quaternary" />
          </Component>
        </li>
      </ul>
    </UiContainer>
    <UiDialog v-model="showLocationDialog">
      <ChooseLocation @close="showLocationDialog = false" />
    </UiDialog>
  </main>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { ChooseLocation } from '@/4_features/Location'
import { FAQLinks } from '@/5_entities/FAQ'
import { useLocation } from '@/5_entities/Location'
import { UiButton, UiContainer, UiDialog, UiGradientBackground, UiIcon } from '@/6_shared/ui'

const showLocationDialog = ref(false)
const { currentLocation } = storeToRefs(useLocation())
</script>
