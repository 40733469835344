<template>
  <div class="z-30 w-full">
    <nav
      id="test"
      ref="navigation"
      class="relative rounded-t-3xl border-t border-t-secondary bg-primary transition-colors will-change-transform"
    >
      <ul class="flex items-center">
        <li v-for="navItem in navigationMenu" :key="navItem.title" class="w-1/3">
          <RouterLink :to="{ name: navItem.to }" v-slot="{ isActive }">
            <span
              :id="`${navItem.to}Nav`"
              class="flex size-full flex-col items-center py-3 text-center text-xxs text-icon-tertiary"
              :class="{
                '!text-icon-primary':
                  isActive || navItem?.child?.includes((route?.name as string) || '')
              }"
            >
              <ThemeIcon :name="navItem.icon" class="size-6" />
              {{ navItem.title }}
            </span>
          </RouterLink>
        </li>
      </ul>
      <ConfirmCity class="absolute bottom-full right-4 -mb-0.5" />
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { ConfirmCity } from '@/4_features/Location'
import { ThemeIcon } from '@/5_entities/Configuration'

const navigationMenu = [
  {
    title: 'Лучшее',
    icon: 'star',
    to: 'HomeView',
    child: ['CategoryDateView', 'CategoryItemView']
  },
  {
    title: 'Поиск',
    icon: 'magnifying-glass',
    to: 'SearchView',
    child: ['SearchCategoryView']
  },
  {
    title: 'Настройки',
    icon: 'cog-6-tooth',
    to: 'SettingsView',
    child: ['FAQView']
  }
]

const route = useRoute()
</script>
